import React, { useState } from 'react';
import '../CSS/Date.css'; 
import { useNavigate } from 'react-router-dom';

const DateComponent = () => {
  const [noButtonPosition, setNoButtonPosition] = useState({ top: '50%', left: '50%' });
  const navigate = useNavigate();

  const moveNoButton = () => {
    const top = Math.random() * 80 + 10; // Random top position between 10% and 90%
    const left = Math.random() * 80 + 10; // Random left position between 10% and 90%
    setNoButtonPosition({ top: `${top}%`, left: `${left}%` });
  };

  const handleYesClick = () => {
    navigate('/select-date_Spanish'); // Redirect to the date selection page
  };

  return (
    <div className="date-app">
      <h1>Quieres Salir en Una Cita?</h1>
      <button className="yes-button" onClick={handleYesClick}>
        Si
      </button>
      <button
        className="no-button"
        style={{ top: noButtonPosition.top, left: noButtonPosition.left }}
        onMouseEnter={moveNoButton}
        onTouchStart={moveNoButton} // Handle tap event
      >
        No
      </button>
    </div>
  );
};

export default DateComponent;

