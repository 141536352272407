export default {
    "images": [
        {
            "id": "1",
            "url": "../images/Me-2.png",
            "height": 100,
        },
        {
            "id": "1",
            "url": "../images/Me-3.png",
            "height": 100,
        },
        {
            "id": "1",
            "url": "../images/Me-4.png",
            "height": 100,
        },
        {
            "id": "1",
            "url": "../images/Me-5.png",
            "height": 100,
        },
        {
            "id": "1",
            "url": "../images/Me-6.png",
            "height": 100,
        }, {
            "id": "1",
            "url": "../images/Me.png",
            "height": 100,
        }
    ]
}