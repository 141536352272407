//Other Imports
import React from 'react';
import './App.css';
import {Routes, Route} from 'react-router-dom';



// Components
import Home from './Components/Home';
import Resume from './Components/Resume';
import Res from './Files/ResumePDF.pdf';
import Projects from './Components/Projects.jsx';
import Travel from './Components/Travel.jsx';
import Recipes from './Components/Recipes';
import Rec from './Files/Estofado-Recipe.pdf';
import Ninder from './Components/Data/Ninder';
import Valentine from './Components/Valentine';
import Date from './Components/Date';
import Date_Spanish from './Components/Date-Spanish';
import SelectDate from './Components/SubComponents/Select-Date';
import SelectDate_Spanish from './Components/SubComponents/Select-Date_Spanish';
import emailjs from 'emailjs-com';
import LocationSpoof from './Components/SubComponents/LocationSpoof';

function App() {

  

  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Resume' element={<Resume/>} />
        <Route path='/Resume/Res' element={<Res/>} />
        <Route path='/projects/' element={<Projects/>} />
        <Route path='/Travel' element={<Travel/>} />
        <Route path='/Recipes' element={<Recipes/>} />
        <Route path='/Recipes/Rec' element={<Rec/>} />
        <Route path='/ninder/' element={<Ninder/>} />
        <Route path='/Valentine/' element={<Valentine/>} />
        <Route path='/Date/' element={<Date/>} />
        <Route path='/Date-Spanish/' element={<Date_Spanish/>} />
        <Route path='Select-Date/' element={<SelectDate/>}/>
        <Route path='Select-Date_Spanish/' element={<SelectDate_Spanish/>}/>
        <Route path='/LocationSpoof' element={<LocationSpoof />} />
        <Route path='*' element={<div>404 Not Found</div>} />
      </Routes>
    </div>
  );
}

export default App;