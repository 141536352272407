import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import '../../CSS/SelectDate.css';

const SelectDate = () => {
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [dateType, setDateType] = useState('');
  const [additionalQuestions, setAdditionalQuestions] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [instagram, setInstagram] = useState('');

  useEffect(() => {
    emailjs.init('ROBND5jhnBWzpofFn'); // Initialize EmailJS with your Public Key
  }, []);

  const sendInvite = (e) => {
    e.preventDefault();

    const templateParams = {
      name: name,
      date: date,
      time: time,
      dateType: dateType,
      additionalQuestions: additionalQuestions,
      userEmail: userEmail,
      instagram: instagram,
      to_email: userEmail, // This will send the email to the user's email address
      confirmation_email: 'nradstake@yahoo.com' // Send confirmation to yourself
    };

    console.log('Sending invite with params:', templateParams);

    emailjs.send('service_mz2dyzw', 'template_pek52et', templateParams)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Invite sent successfully!');
      }, (error) => {
        console.error('FAILED...', error.text);
        alert('Failed to send invite. Please try again.');
      });
  };

  return (
    <div className="select-date-app">
      <h2>Select Date and Time</h2>
      <p>I'm usually free after 18:00 on weekdays and should be open most weekends.</p>
      <form onSubmit={sendInvite}>
        <div>
          <label>Name: </label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div>
          <label>Date: </label>
          <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
        </div>
        <div>
          <label>Time: </label>
          <input type="time" value={time} onChange={(e) => setTime(e.target.value)} required />
        </div>
        <div>
          <label>What kind of date?: </label>
          <input type="text" value={dateType} onChange={(e) => setDateType(e.target.value)} required />
        </div>
        <div>
          <label>Any Additional Questions?: </label>
          <textarea value={additionalQuestions} onChange={(e) => setAdditionalQuestions(e.target.value)} />
        </div>
        <div>
          <label>Your Email: </label>
          <input type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} required />
        </div>
        <div>
          <label>Your Instagram: </label>
          <input type="text" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
        </div>
        <button type="submit">Send Invite</button>
      </form>
    </div>
  );
};

export default SelectDate;
