export default [
    {
        id: 1,
        title: "CFT Website",
        coverImg: "../images/CFTWebsite.png",
        file: "https://centralfloridatapping.com/",
        description: "Made a company website using CSS, Javascript & Html. This website is currently being used by the company.",
    },
    {
        id: 2,
        title: "Simulation Project",
        coverImg: "../images/Model4.png",
        file: './Files/Model4.mp4',
        description: "Created a simulation using C++, OpenGL and Simio. This simulation helped implement a new manufacturing process for a gasket assembly.",
    },
    {
        id: 3,
        title: "TRG Cost Analysis",
        coverImg: "../images/CostAnalysis.png",
        file: './Files/TRG_Cost_Analysis.pdf',
        description: "Made an initial cost analysis on a gasket company, identifying current expences and potential cost savings.",
    },
    {
        id: 4,
        title: "CFT Production Analysis",
        coverImg: "../images/Prod_Analysis.png",
        file: './Files/ProductionAnalysis/tabstrip.htm',
        description: "Utilized time studies to conduct production studies for a construction company",
    },
    {
        id: 5,
        title: "Ninder",
        coverImg: "../images/NinderScreen.png",
        file: "#/ninder/",
        description: "Basically like Tinder but its just me and you can't swipe left",
    },
    {
        id: 6,
        title: "Github",
        coverImg: "../images/git-pic.jpg",
        file: "https://github.com/Coaxe932",
        description: "See all my github work",
    },
    {
        id: 7,
        title: "Chat GPT Project",
        coverImg: "../images/GPT-Front.jpg",
        file: "#/GPT/",
        description: "My version of Chat GPT",
    },
    {
        id: 8,
        title: "Blog",
        coverImg: "../images/Nick-Blog.jpg",
        file: "#/Blog/",
        description: "My Blog Post",
    },
    {
        id: 9,
        title: "Date",
        coverImg: "../images/Valentine-Img.jpg",
        file: "#/Date",
        description: "Date App",
    },
    {
        id: 10,
        title: "Date - Spanish",
        coverImg: "../images/Valentine-Img.jpg",
        file: "#/Date-Spanish",
        description: "Date App Spanish",
    },
    {
        id: 11,
        title: "Location Spoof",
        coverImg: "../images/location.jpg",
        file: "#/LocationSpoof",
        description: "Location Spoof",
    },
]