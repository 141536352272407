import React, { useState } from 'react';
import '../CSS/Valentine.css'; 

const ValentineApp = () => {
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState('');

  const noButtonTexts = [
    'No',
    'Really?',
    'Are you sure?',
    'You Might Regret It',
    'Imagine all the fun we could have',
    'Just say yes!',
    'Wow Really?'
  ];

  const handleNoClick = () => {
    if (count < 4) {
      setCount(count + 1);
    } else {
      setCount(count + 1); 
    }
  };

  const handleYesClick = () => {
    setMessage('Yay! You said Yes! ❤️');
  };

  return (
    <div className="valentine-app">
      <h1 className='valentine-text'>Will you be my Valentine?</h1>
      {message ? (
        <h2>{message}</h2>
      ) : (
        <>
          <button className={`yes-button ${count > 0 ? `grow-${count}` : ''}`} onClick={handleYesClick}>
            Yes
          </button>
          {count < 5 && (
            <button className="no-button" onClick={handleNoClick}>
              {noButtonTexts[count]}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default ValentineApp;
