import React, { useEffect, useState } from 'react';
import '../../CSS/LocationSpoof.css';

const LocationSpoof = () => {
  const [location, setLocation] = useState({ latitude: null, longitude: null, error: null });
  const [newLatitude, setNewLatitude] = useState('');
  const [newLongitude, setNewLongitude] = useState('');

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null
          });
        },
        error => setLocation({ latitude: null, longitude: null, error: error.message })
      );
    } else {
      setLocation({ latitude: null, longitude: null, error: 'Geolocation is not supported by this browser.' });
    }
  }, []);

  const handleUpdateLocation = () => {
    setLocation({
      latitude: parseFloat(newLatitude),
      longitude: parseFloat(newLongitude),
      error: null
    });
  };

  return (
    <div className="location-spoof">
      <h1>Location Spoofing</h1>
      {location.error ? (
        <p>Error: {location.error}</p>
      ) : (
        <div>
          <p>Latitude: {location.latitude}</p>
          <p>Longitude: {location.longitude}</p>
        </div>
      )}
      <div className="input-container">
        <input
          type="number"
          placeholder="Enter new latitude"
          value={newLatitude}
          onChange={(e) => setNewLatitude(e.target.value)}
        />
        <input
          type="number"
          placeholder="Enter new longitude"
          value={newLongitude}
          onChange={(e) => setNewLongitude(e.target.value)}
        />
        <button onClick={handleUpdateLocation}>Update Location</button>
      </div>
    </div>
  );
};

export default LocationSpoof;
